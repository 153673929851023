export default {
  'PING': 'PING',
  'GRAPHQL': 'GRAPHQL',
  'PING_INITIAL_CALL': 'PING_INITIAL_CALL',
  'LOGIN': 'Login',
  'CREATE_PASSWORD': 'CreatePassword',
  'CREATE_PIN': 'CreatePin',
  'LOGOUT': 'Logout',
  'MOSAIC_LOGIN_METHOD' : 'MosaicLoginMethod',
  'INTERSTITIAL_MODAL': 'InterstitialModal',
  'DESKTOP': 'DESKTOP',
  'LGDESKTOP': 'LGDESKTOP',
  'XLGDESKTOP': 'XLGDESKTOP',
  'TABLET': 'TABLET',
  'MOBILE': 'MOBILE',
  'LOCAL': 'LOCAL',
  'DEVELOPMENT': 'DEVELOPMENT',
  'PRODUCTION': 'PRODUCTION',
  'PROD': 'PROD',
  // Fixed Constants
  'FIXED_PADDING': 24,
  'IS_MODAL_OPEN': 'IS_MODAL_OPEN',
  'SHOW_TRADE_BLOTTER': 'showTradeBlotter',
  'IS_SELECTED': 'isSelected',
  'IS_NEW_ACCOUNT': 'isNewAccount',
  'FIRM': 'firm',
  'ACCOUNT': 'account',
  'SHARE_CLASS': 'shareClass',
  'TRADE_DATE': 'tradeDate',
  'TRADE_TYPE': 'tradeType',
  'AMOUNT_TYPE': 'amountType',
  'TRADE_AMOUNT': 'tradeAmount',
  'SETTLEMENT_CYCLE': 'settlementCycle',
  'HOME_AWAY_TYPE': 'homeAwayType',
  'HOME': 'home',
  'AWAY': 'away',
  'CURRENCY_DATA_KEY': 'currency',
  'SHARES_DATA_KEY': 'shares',
  'AMOUNT_TYPE_ALL_DATA_KEY': 'all',
  'TRADE_AMOUNT_ALL_DATA_KEY': 'All',
  'TRADE_TYPE_REDEEM_DATA_KEY': 'redeem',
  'TRADE_TYPE_PURCHASE_DATA_KEY': 'purchase',
  'TRADE_CALENDER_ENABLED_FOR_NUMBER_OF_DAYS_IN_FUTURE': 4,
  'DISPLAY_POSITION': 'displayPosition',
  'PAYMENT_INSTRUCTIONS': 'paymentInstructions',
  'PAYMENT_INSTRUCTION_ID': 'paymentInstructionId',
  'TA_ACCOUNT': 'taAccount',
  'ENTRY': 'ENTRY',
  'REVIEW': 'REVIEW',
  'SUMMARY': 'SUMMARY',
  'CURRENT_TRADE_STEP': 'currentTradeStep',
  'INLINE_ERRORS': 'inlineErrors',
  'INLINE_WARNINGS': 'inlineWarnings',
  'INLINE_INFORMATION': 'inlineInformation',
  'ERROR_TRADES_COUNT': 'errorTradesCount',
  'WARNING_TRADES_COUNT': 'warningTradesCount',
  'ANALYTICS_ADD_SOURCE_BLOTTER': 'Blotter',
  'ANALYTICS_SUCCESS_STATUS': 'success',
  'ANALYTICS_FAIL_STATUS': 'fail',
  'ANALYTICS_ROW': 'row',
  'ANALYTICS_BLOTTER_EXPAND_EVENT': 'blotter_expand',
  'ANALYTICS_BLOTTER_CLOSE_EVENT': 'blotter_close',
  'ANALYTICS_ADD_NEW_ROW_EVENT': 'add_new_row',
  'ANALYTICS_REMOVE_ROW_EVENT': 'remove_row',
  'ANALYTICS_ADD_ACCOUNT_EVENT': 'add_account',
  'ANALYTICS_ADD_ACCOUNT_SUBMIT_EVENT': 'add_account_submit',
  'ANALYTICS_ADD_ACCOUNT_CANCEL_EVENT': 'add_account_cancel',
  'ANALYTICS_VERIFY_TRADE_EVENT': 'verify_trade',
  'ANALYTICS_REMOVE_ALL_TRADES_EVENT': 'remove_all_trades',
  'ANALYTICS_FIELD_INTERACTION_EVENT': 'field_interaction',
  'ANALYTICS_INLINE_ERROR_EVENT': 'inline_error',
  'ANALYTICS_AMOUNT_SHORTCUT_EVENT': 'amount_shortcut',
  'ANALYTICS_GO_BACK_EVENT': 'go_back',
  'ANALYTICS_PLACE_TRADE_EVENT': 'place_trade',
  'ANALYTICS_VIEW_TRADE_ACTIVITY_EVENT': 'view_trade_activity',
  'ANALYTICS_SAVE_VERSION_ERROR_EVENT': 'save_version_error',
  'ANALYTICS_MOUSE_INTERACTION': 'Mouse',
  'ANALYTICS_KEYBOARD_INTERACTION': 'Keyboard',
  'ANALYTICS_COMPONENT_ADD_NEW_ACCOUNT': 'Add New Account',
  'ANALYTICS_PAGE_HIERARCHY': 'ANALYTICS_PAGE_HIERARCHY',
  'SETTLEMENT_CYCLE_CURRENT': 'current',
  'SETTLEMENT_CYCLE_FUTURE': 'future',
  'SAVE_TRADES': 'SAVE_TRADES',
  'ACKNOWLEDGE_AND_PROCEED': 'ACKNOWLEDGE_AND_PROCEED',
  'VERIFY_TRADES': 'VERIFY_TRADES',
  'PLACE_TRADES': 'PLACE_TRADES',
  'ALERT': 'alert',
  'ERROR': 'error',
  'WARNING': 'warning',
  'LAST_STEP': 'laststep',
  'INFO': 'info',
  'NOTE': 'note',
  'REVIEW_COMPONENT_TYPE': 'Text',
  'SNACKBAR_HIDE_TIME': 10000,
  'FILE_DOWNLOAD_POLLING_INTERVAL': 10000,
  'SHOW_NEW_ACCOUNT_MODAL': 'showNewAccountModal',
  'REDEMPTION_FEE_MODAL': 'REDEMPTION_FEE_MODAL',
  'SHOW_REDEMPTION_FEE_MODAL': 'showRedemptionFeeModal',
  'STATIC_SECTION_TEXT': 'globalStaticText',
  'DYNAMIC_SECTION_TEXT': 'globalDynamicText',
  'COPYRIGHT_TEXT': 'copyRightText',
  'GLOBAL_PRIVACY_LINK': 'privacyPolicy',
  'COOKIE_LINK': 'cookieLink',
  'LINKS': 'links',
  'BIN': 'bin',
  'DRAFT_TRADES': 'draftTrades',
  'ADD_SOURCE': 'addSource',
  'ACTION_TYPE': 'actionType',
  'TRADE_BLOTTER_ID': 'tradeBlotterId',
  'DELETED_TRADE_DISPLAY_POSITION':'deletedTradeDisplayPosition',
  'IS_VERIFY_SUCCESS': 'isVerifySuccess',
  'FIELD_NAME': 'fieldName',
  'VALUE_CHOSEN': 'valueChosen',
  'INPUT_METHOD': 'inputMethod',
  'ERROR_CODES': 'errorCodes',
  'IS_PLACE_SUCCESS': 'isPlaceSuccess',
  'HOME_ROUTE': '/',
  'CONTENT_ROUTE': '/content',
  'LOGIN_ROUTE': 'login',
  'LOGOUT_ROUTE': 'logout',
  'CREATE_PASSWORD_ROUTE': 'newpassword',
  'CREATE_PIN_ROUTE': 'newpin',
  'FORGOT_PASSWORD_ROUTE': 'forgot-password',
  'CLIENT_MIGRATION_INFO_MODAL': 'transferring-to-',
  'PORTFOLIO_ROUTE': 'portfolio',
  'FUND_FINDER_ROUTE': 'fund-finder',
  'REPORTING_ROUTE': 'reporting',
  'FUND_TRACKER_ROUTE': 'fund-tracker',
  'REPORTS_ROUTE': 'reports',
  'ALL_REPORTS_TAB_ROUTE': 'reports/allreports',
  'REPORT_MY_REPORTS_TAB_ROUTE': 'reports/my-reports',
  'SAVED_REPORT_ROUTE': 'reports/saved-report',
  'REPORT_AB_ROUTE': 'reports/account-balance',
  'REPORT_HTA_ROUTE': 'reports/historical-transactions',
  'REPORT_CF_ROUTE': 'reports/class-fees',
  'REPORT_MDA_ROUTE': 'reports/monthly-dividend',
  'REPORT_MSF_ROUTE': 'reports/monthly-service',
  'REPORT_DCAL_ROUTE': 'reports/debit-credit-accounts',
  'REPORT_GL_ROUTE': 'reports/gain-loss-onshore',
  'REPORT_GL_OFFSHORE_ROUTE': 'reports/gain-loss-offshore',
  'REPORT_MCD_ROUTE': 'reports/ex-post-costs',
  'REPORT_CTS_ROUTE': 'reports/current-trade-status',
  'REPORT_CTTF_ROUTE': 'reports/current-trade-totals',
  'REPORT_WS_ROUTE': 'reports/wire-settlement',
  'REPORT_HTAE_ROUTE': 'reports/trade-approval-exceptions',
  'REPORT_DCTA_ROUTE': 'reports/debit-credit-transactions',
  'REPORT_DCSS_ROUTE': 'reports/debit-credit-settlement',
  'REPORT_DCFS_ROUTE': 'reports/debit-credit-activity',
  'REPORT_FB_ROUTE': 'reports/fund-balances',
  'REPORT_RF_ROUTE': 'reports/fund-rates-factors',
  'REPORT_AB_ROUTE_LEGACY': 'reports/ab',
  'REPORT_HTA_ROUTE_LEGACY': 'reports/hta',
  'REPORT_CF_ROUTE_LEGACY': 'reports/cf',
  'REPORT_MDA_ROUTE_LEGACY': 'reports/mda',
  'REPORT_MSF_ROUTE_LEGACY': 'reports/msf',
  'REPORT_DCAL_ROUTE_LEGACY': 'reports/dcal',
  'REPORT_GL_ROUTE_LEGACY': 'reports/gl',
  'REPORT_MCD_ROUTE_LEGACY': 'reports/mcd',
  'REPORT_CTS_ROUTE_LEGACY': 'reports/cts',
  'REPORT_CTTF_ROUTE_LEGACY': 'reports/cttf',
  'REPORT_WS_ROUTE_LEGACY': 'reports/ws',
  'REPORT_HTAE_ROUTE_LEGACY': 'reports/htae',
  'REPORT_DCTA_ROUTE_LEGACY': 'reports/dcta',
  'REPORT_DCSS_ROUTE_LEGACY': 'reports/dcss',
  'REPORT_DCFS_ROUTE_LEGACY': 'reports/dcfs',
  'REPORT_FB_ROUTE_LEGACY': 'reports/fb',
  'REPORT_RF_ROUTE_LEGACY': 'reports/rf',
  'FUND_TRACKER_NAV': 'fund-tracker/nav',
  'FUND_TRACKER_PERFORMANCE': 'fund-tracker/performance',
  'FUND_TRACKER_LIQUIDITY': 'fund-tracker/liquidity',
  'FUND_TRACKER_DURATION': 'fund-tracker/duration',
  'FUND_TRACKER_ASSETS': 'fund-tracker/assets',
  'FUND_TRACKER_MY_HOLDINGS': 'fund-tracker/my-holdings',
  'USER_SEARCH_ROUTE': 'user-search',
  'TRADING_ROUTE': 'trading',
  'TRADE_ACTIVITY_ROUTE': 'trade-activity',
  'TRADE_ACTIVITY_APPROVALS': 'trade-activity/approvals',
  'TRADE_CURRENT_TRADE_STATUS': 'trade-activity/transactions',
  'REPORTING_NEW': 'trade-activity/reporting',
  'PREFERENCES_ROUTE': 'preferences',
  'PREFERENCES_DISPLAY_SETTINGS_ROUTE': 'preferences/displaysettings',
  'PREFERENCES_EMAIL_CONFIGURATION_ROUTE': 'preferences/emailnotifications',
  'PREFERENCES_FUND_TRACKER_THRESHOLDS_ROUTE': 'preferences/fundtrackerthresholds',
  'PREFERENCES_INVESTMENT_POLICY_ROUTE': 'preferences/investmentpolicy',
  'BUILD_INFO_ROUTE': 'build-info',
  'FAQ_ROUTE': 'faq',
  'GLOSSARY_ROUTE': 'glossary',
  'GSCP_ROTUE': '/gcsp/portal/portfolio',
  'ERROR_ROUTE': '*',
  'CSS_DURATION_NADA': 0,
  'CSS_DURATION_CHEETAH': 160,
  'CSS_DURATION_HORSE': 330,
  'CSS_DURATION_TURTLE': 400,
  'CSS_DURATION_SPIDER': 500,
  'CSS_DURATION_ELEPHANT': 660,
  'CSS_DURATION_SNAIL': 1000,
  'CSS_DURATION_SLOTH': 3000,
  'INPUTS_API_FAILED': 'INPUTS_API_FAILED',
  'SEARCH_API_FAILED': 'SEARCH_API_FAILED',
  'TRADING_FEATURE_ENTITLEMENT': 'TRADING',
  'INVESTMENT_POLICY': 'INVESTMENTPOLICY',
  'SHELL_ACCOUNT_OPTIONS': 'shellAccountOptions',
  'MESSAGE_STATUS_UNREAD': 'UNREAD',
  'MESSAGE_PRIORITY_IMPORTANT': 'IMPORTANT',
  'MESSAGE_PRIORITY_CRITICAL': 'CRITICAL',
  'MESSAGE_PRIORITY_NORMAL': 'NORMAL',
  'MESSAGE_STATUS_READ': 'READ',
  'MESSAGE_CENTER_ANALYTICS_PAGE_LOAD': 'page_load',
  'MESSAGE_CENTER_ANALYTICS_OPEN': 'open',
  'MESSAGE_CENTER_ANALYTICS_CLOSE': 'close',
  'MESSAGE_CENTER_ANALYTICS_HYPERLINK': 'link_clicked',
  'MESSAGE_CENTER_CRITICAL_MESSAGE': 'Critical Message',
  'MESSAGECENTER': 'MESSAGECENTER',
  'IFRAME_OPTIMAL_WIDTH': 1344,
  'PAGE_MINIMUM_WIDTH': 1344,
  'REACT_ROUTER': 'ReactRouter',
  'EXTERNAL_LINK': 'ExternalLink',
  'THIRD_PARTY_EXTERNAL_LINK': 'ThirdPartyExternalLink',
  'RESOURCE_NAME_CONTACT_US': 'CONTACTUS',
  'RESOURCE_NAME_TRANSPARENCY_INSIGHTS': 'TRANSPARENCYINSIGHTS',
  'TRADE_ACTIVITY': 'TradeActivity',
  'REPORTS': 'Reports',
  'FUND_TRACKER': 'FundTracker',
  'PREFERENCES': 'Preferences',
  'REPORTING_ROUTING_PATH_ID': 'Reporting',
  'TRANSPARENCY_INSIGHTS_VENDER_NAME': 'imoneynet',
  'ACCOUNT_IDENTIFIER': 'accountIdentifier',
  'FUND_IDENTIFIER': 'fundIdentifier',
  'REPORTING_IFRAME_WIDTH': 900,
  'FUND_TRACKER_IFRAME_WIDTH': 1090,
  'ACCOUNT_DETAILS_IFRAME_WIDTH': 885,
  'CTSEntitlement': 'CTS',
  'REPORTING_DROPDOWN_WIDTH': 276,
  'US_LOCALE': 'en-US',
  'HTML': 'html',
  'PORTFOLIO_DATA_UNIQUE_KEY': 'accountId',
  'FUND_FINDER_DATA_UNIQUE_KEY': 'shareClassId',
  'FUND_FINDER_MULTISORT_COLUMN1': 'investmentManager',
  'FUND_FINDER_MULTISORT_COLUMN2': 'fundName',
  'STATE_COOKIE': 'mod_auth_openidc_state',
  'LOCAL_STORAGE_USER_KEY': 'USER_NAME',
  'SEARCH': 'search',
  'TARGET': 'target',
  'ORIGIN': 'origin',
  'PROTOCOL': 'protocol',
  'HOSTNAME': 'hostname',
  'PORT': 'port',
  'PATHNAME': 'pathname',
  'navRootConfig': 'navRootConfig',
  'countryDisclaimerData': 'countryDisclaimerData',
  'ZENDESK_HOST': 'https://help.gsmosaic.gs.com',
  'tkPendingApproval': 'Pending Approval',
  'TRADE_APPROVAL_FUND_NAME': 'fundName',
  'TRADE_APPROVAL_ACCOUNT_NAME': 'name',
  'TRADE_APPROVAL_ACCOUNT_BIN': 'bin',
  'TRADE_APPROVAL_ACCOUNT_TA_ACCOUNT': 'taAccount',
  'PREFERENCE_EMAIL_NOTIFICATIONS': 'EmailNotifications',
  'PDF_DOWNLOAD_MAX_COLUMN_WARNING_LIMIT': 20,
  'REPORTINGNEW': 'REPORTINGNEW',
  'REPORTINGLEGACY': 'REPORTINGLEGACY',
  'MIMIC': 'MIMIC',
  'MIMIC_SUCCESS': 'MIMIC_SUCCESS',
  'IS_SAVING_PREF': 'IS_SAVING_PREF'
};

export const TRADEBLOTTER_FUND_FEES = {
  'DISCRETIONARY_FEE': 'SUBJECT_TO_DISCRETIONARY_FEE'
};

export const SECONDARY_NAV_LIST = ['help', 'messages', 'apps', 'user', 'all'];

export const MONTH_LIST = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const PAGE_NAV_ITEM = {
  'TRADEAPPROVAL' : 'TRADEAPPROVAL',
  'TRADEIMPORT': 'TRADEIMPORT',
  'TRADING': 'TRADING',
  'INVESTMENTPOLICY':'INVESTMENTPOLICY'
};

export const ALLOWED_FEATURES = {
  'PDFEXPORT' : 'PDFEXPORT'
};

export const DATE_TIME_FORMATTERS = {
  'YYYY_MM_DD': 'YYYY-MM-DD',
  'HH_mm': 'HH:mm',
  'DD_MMM_YYYY_HH_MM_SS_A_Z': 'DD.MMM.YYYY h:mm:ss A z',
  'DDMMMYYYY': 'DDMMMYYYY',
  'DDMMMYYYYHHmmss': 'DDMMMYYYYHHmmss'
};

export const RANGE_BUTTONS = {
  'TODAY': 'TODAY',
  'ONE_WEEK': 'ONE_WEEK',
  'PRIOR_SEVEN_DAYS': 'PRIOR_SEVEN_DAYS',
  'ONE_MONTH': 'ONE_MONTH',
  'CURRENT_MONTH': 'CURRENT_MONTH',
  'PRIOR_MONTH': 'PRIOR_MONTH',
  'PRIOR_BUSINESS_DAY': 'PRIOR_BUSINESS_DAY',
  'YESTERDAY': 'YESTERDAY',
  'T-1': 'T-1',
  'MTD': 'MTD',
  'LAST_THREE_MONTHS': 'LAST_THREE_MONTHS',
  'CURRENT_QUARTER': 'CURRENT_QUARTER',
  'LAST_QUARTER': 'LAST_QUARTER',
  'PRIOR_QUARTER': 'PRIOR_QUARTER',
  'PRIOR_YEAR': 'PRIOR_YEAR',
  'CUSTOM': 'CUSTOM',
};

export const ROUTE_ID = {
  'INVESTMENT_POLICY': 'InvestmentPolicy',
};

export const FILE_DOWNLOAD_STATE = {
  'INITIATED': 'INITIATED',
  'READY_TO_DOWNLOAD': 'READY_TO_DOWNLOAD',
  'DOWNLOADED': 'DOWNLOADED',
  'FAILED': 'FAILED',
};

export const FILE_DOWNLOAD_TYPES = {
  'EXCEL': 'excel',
  'PDF': 'pdf',
};

export const FILE_DOWNLOAD_ORIGIN = {
  'PORTFOLIO': 'PORTFOLIO',
  'FUND_FINDER': 'FUND_FINDER',
  'REPORTING':'REPORTING',
  'TRANSACTIONS':'TRANSACTIONS'
};

export const FAQ_GLOSSARY_KEYS = {
  FAQ: 'FAQ',
  GLOSSARY: 'Glossary',
  MIFIDII_DOC_UPDATE_YEAR: '2023'
};

export const STATIC_DISCLAIMER_KEYS = [
  'privacyPolicyData',
  'cookieLinkData',
  'copyRightData',
  'termsAndConditionsDataVersion',
  'termsAndConditionsData',
  'globalDisclaimerStaticData',
  'globalDisclaimerDynamicData',
  'gsOffShoreFundsDisclaimerData',
  'portfolioDisclaimerData',
  'reportingTransactionReportDisclaimerData',
  'reportingFundReportDisclaimerData',
  'reportingAccountReportDisclaimerData',
  'reportingExPostReportDisclaimerData',
  'fundfinderDisclaimerData',
  'fundtrackerDisclaimerData',
  'fundTrackerShortDisclaimerData',
  'fundtrackerNavGroupDisclaimerData',
  'fundtrackerPerformanceGroupDisclaimerData',
  'fundtrackerLiquidityGroupDisclaimerData',
  'fundtrackerDurationGroupDisclaimerData',
  'fundtrackerAssetsGroupDisclaimerData',
  'esgDisclaimer',
  'navAsOfFootnote',
  'performanceAsOfFootnote',
  'fundAssetsAsOfFootnote',
  'durationAsOfFootnote',
  'liquidityAsOfFootnote',
  'fdicDisclaimer'
];

export const DEFAULT_DISCLAIMER_KEYS = [
  'globalDisclaimerStaticData',
  'globalDisclaimerDynamicData',
  'copyRightData'
];

export const DISCLAIMER_KEYS_FOR_PAGES = {
  'portfolioDisclaimerData': ['Portfolio'],
  'reportsDisclaimerData': ['current-trade-status', 'current-trade-totals', 'debit-credit-activity', 'debit-credit-transactions', 'debit-credit-settlement', 'trade-approval-exceptions', 'historical-transactions', 'wire-settlement', 'monthly-dividend', 'fund-balances', 'fund-rates-factors', 'account-balance', 'class-fees', 'debit-credit-accounts', 'gain-loss-onshore', 'gain-loss-offshore', 'monthly-service', 'ex-post-costs'],
  'reportingTransactionReportDisclaimerData': ['current-trade-status', 'current-trade-totals', 'debit-credit-activity', 'debit-credit-transactions', 'debit-credit-settlement', 'trade-approval-exceptions', 'historical-transactions', 'wire-settlement', 'monthly-dividend'],
  'reportingFundReportDisclaimerData': ['fund-balances', 'fund-rates-factors'],
  'reportingAccountReportDisclaimerData': ['account-balance', 'class-fees', 'debit-credit-accounts', 'gain-loss-onshore', 'gain-loss-offshore', 'monthly-service'],
  'reportingExPostReportDisclaimerData': ['ex-post-costs'],
  'fundfinderDisclaimerData': ['FundFinder'],
  'fundtrackerDisclaimerData': ['Nav', 'Performance', 'Liquidity', 'Duration', 'Assets'],
  'fundtrackerNavGroupDisclaimerData': ['Nav'],
  'fundtrackerPerformanceGroupDisclaimerData': ['Performance'],
  'fundtrackerLiquidityGroupDisclaimerData': ['Liquidity'],
  'fundtrackerDurationGroupDisclaimerData': ['Duration'],
  'fundtrackerAssetsGroupDisclaimerData': ['Assets']
};

export const SHORT_DISCLAIMER_KEYS_FOR_PAGES = {
  'fundTrackerShortDisclaimerData': ['Nav', 'Performance', 'Liquidity', 'Duration', 'Assets'],
}

export const SnackbarTypes = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  ALERT: "alert",
  NOTE: "note",
  LASTSTEP: "laststep",
  SUCCESS: "success",
};

export const SnackbarAutoHideTypes = [
  SnackbarTypes.SUCCESS,
  SnackbarTypes.WARNING,
];

export const REPORT_PAGE_IDS = {
  AB: 'account-balance',
  CF: 'class-fees',
  CTS: 'current-trade-status',
  CTTF: 'current-trade-totals',
  DCAL: 'debit-credit-accounts',
  DCSS: 'debit-credit-settlement',
  DCTA: 'debit-credit-transactions',
  FB: 'fund-balances',
  GL: 'gain-loss-onshore',
  GLOFF: 'gain-loss-offshore',
  HTAE: 'trade-approval-exceptions',
  MCD: 'ex-post-costs',
  MDA: 'monthly-dividend',
  MSF: 'monthly-service',
  RF: 'fund-rates-factors',
  WS: 'wire-settlement',
};
