import { setAnalyticsData } from "./dtmHelper";
import { getPageNameAndSections, extendOnlyObject } from "./helper";

let analyticsData = setAnalyticsData();

export const eventAnalytics = ({
  pageId = "",
  data = {},
  userData = {},
  labels = {},
  tradeAccess = "",
  configConstants = {},
}) => {
  const {
    userGuid = "",
    isInternal = "",
    jurisdiction = "",
    permissions: { allowFeatures = [] } = {},
  } = userData;
  const { pageName, section, subSection } = getPageNameAndSections(
    configConstants,
    pageId
  );
  const snackbars = document.querySelectorAll(".snackbar .msgTxt");
  const snacbarId = [];
  snackbars &&
    Array.from(snackbars).forEach((snackbar) => {
      const { lastChild: { textContent = "" } = {} } = snackbar;
      Object.keys(labels).forEach((key) => {
        if (textContent && labels[key] === textContent) {
          snacbarId.push(key);
        }
      });
    });

  const analyticsPartialNode = {
    user: {
      currTime: Date.now(),
      profile: {
        userGuid,
        isInternal,
        tradeAccess,
        jurisdiction,
        isEntitledToRebates: allowFeatures.includes("REBATES"),
        isSelfServiceAdmin: allowFeatures.includes("SELFSERVICEADMIN"),
      },
    },
    page: {
      pageInfo: {
        pageName,
        section,
        subSection,
      },
    },
    snackbar: {
      snackbarId: snacbarId,
    },
  };
  analyticsData = extendOnlyObject(analyticsData, analyticsPartialNode);
  analyticsData = extendOnlyObject(analyticsData, data);
  window.digitalData = analyticsData;
  if (data && data.event && data.event.satelliteTrackEventName && window._satellite) {
    window._satellite.track(data.event.satelliteTrackEventName);
  }
};
